/* Side
--------------------------------------------------------- */
.l-side{
	// sp版ブラウザでは100vhだとアドレスバーの高さが入るためflexで下部固定
	// height: 100vh;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	opacity: 0;
	@include sp{
		display: none;
	}
	&__block{
		flex-grow:1;
	}
}
.l-sidemenu{
	width: 200px;
	margin-left: 39px;
	@include sp{
		width: 100%;
		margin-left: 0;
		padding-left: 26px;
	}
	&--top{
		padding-top: 155px;
		@include sp{
			padding-top: 175px;
			padding-bottom: 21px;
		}
	}
	&--bottom{
		padding-bottom: 41px;
		@include sp{
			padding-bottom: 26px;
		}
	}
	li{
		display:block;
		font-size: 1.3rem;
		line-height: 1;
		letter-spacing: 1.3px;
		&:not(:last-child){
			margin-bottom: 17px;
		}
		&.is-current{
			opacity: 0.4;
		}
	}
}