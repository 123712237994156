/* Bass
--------------------------------------------------------- */

$font: Georgia,"Times New Roman", Times, "ヒラギノ明朝 ProN W3","Hiragino Mincho ProN","ＭＳ Ｐ明朝","MS PMincho";

*, *:before, *:after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 14px;
    color: $text;
    font-family: $font;
}

a {
    color: $link;
    text-decoration: none;
    // display: block;
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    &:hover,
    &:hover img {
        opacity: 0.7;
        text-decoration: none;
    }
    button:hover{
        opacity: 1;
    }
}

button{
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    &:hover{
        opacity: 0.7;
    }
}

// img {
//     display: block;
//     max-width: 100%;
// }

input, button, textarea, select {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
    text-align: inherit;
}

input[type="checkbox"], input[type="radio"], label {
    cursor: pointer;
}

textarea {
    resize: none;
}

select::-ms-expand {
  display: none;
}

input::-ms-clear {
    visibility: hidden;
}
input::-ms-reveal {
    visibility: hidden;
}
select::-ms-expand {
    visibility: hidden;
}

// focus
input:focus,
button:focus,
select:focus {
    opacity: 0.7;
}