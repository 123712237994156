/* omotenashi
--------------------------------------------------------- */
.p-omotenashi{
    // width: calc(860px + 0px);
	// padding-left: 108px;
    margin: 0 auto;
	position: relative;
	left: 71px;
	width: 777px;
    width: 900px;
    @include sp{
        position: static;
        width: 100%;
    }
	&__wrap{
		position: relative;
	}
	&__image{
		position: absolute;
		top: -79px;
		left: 77px;
		left: 138px;
		z-index: 0;
        @include sp{
            position: static;
            width: 100%;
            margin: 70px 0 0px;
        }
	}
	&__selection{
		margin-bottom: 124px;
		margin-left: 2px;
		margin-top: -3px;
        width: 60px;
        @include sp{
            position: absolute;
            width: 50px;
        }
	}
	&__title,
	&__title2{
		font-size: 1.2rem;
		line-height: 1;
		letter-spacing: -0.03em;
		font-weight: bold;
		color: $gold;
		position: relative;
		z-index: 1;
		margin-bottom: 13px;
	}
	&__title2{
		margin-bottom: 2px;
		span{
			opacity: 0.6;
			font-family: HiraginoUDSerifStdN-W4;
			font-size: 9px;
			line-height: 2.33;
			text-align: left;
			color: rgba(0, 0, 0, 0.6);
			margin-left: 5px;

		}
	}	
	&__text{
		font-size: 1.2rem;
		line-height: 1.75;
		letter-spacing: 0;
		margin-bottom: 13px;
		position: relative;
		z-index: 1;
	}
	&__text2{
		position: relative;
		z-index: 1;
		dt,dd{
			font-family: HiraginoUDSerifStdN-W4;
			font-size: 1.2rem;
			line-height: 1.8;
			letter-spacing: 0em;
		}
		dt{
			float: left;
			clear: both;
			width: 107px;
		}
		dd{

		}
	}
	&__link{
		margin-top: 20px;
		display: block;
		font-size: 12px;
		i{
			width: 20px;
			margin-right: 14px;
			position: relative;
			top: 3px;
		}
	}
}