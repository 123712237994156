/* collection
--------------------------------------------------------- */
.p-collection-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	height: 100vh;
	@include sp{
		display: block;
		height: auto;
		padding-top: 70px;
	}
	&__link-list{
		overflow: hidden;
		@include sp{
			padding: 0 48px;
		}
		>li{
			float: left;
			&:not(:last-child){
				margin-right: 29px;
				@include sp{
					margin-right: 0;
					margin-bottom: 20px;
				}
			}
			img{
				@include sp{
					width: 100%;
				}
			}
		}
	}
}
.p-collection{
	margin: 0 auto;
    &__slider{
		img{
			&.small{
				width: 480px;
			}
			&.midium{
				width: 751px;
			}
			&.large{
				width: 960px;
			}
		}
		@include sp{
			display: none;
		}
	}
}
.p-slide-centering{
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	@include sp{
		height: auto;
		display: block;
	}
	img{
		display: none;
		position: absolute;	
	}
}

.p-sp-collection{
	display: none;
	@include sp{
		display: block;
		margin-top: 70px;
		padding-bottom: 26px;
	}
	&__list{
		font-size: 0;
		padding: 0 15px;
		>li{
			margin-bottom: 15px;
			img{
				width: 100%;
				margin: 0 auto;
				display: block;
				&.small{
					width: 63%;
					margin: 80px auto;
				}
				&.medium{
					width: 86.5%;
					margin: 38px auto;
				}
				&.large{
					width: 100%;
				}
			}
		}
	}
}
// slide
.p-center-slide{
	position: absolute;
	top: -25px;
	left: 75px;
	z-index: 10;
}
.p-slide-area{
	display: block;
	width: 716px;
	margin: 0 auto;
	font-size: 0;
	position: relative;
	// padding-top: 23px;
	@include sp{
		display: none;
	}
}
.p-slide{
	width: 308px;
	height:410px;
	display: inline-block;
	margin: 0 25px;
}
.js-slide{
    position:relative;
    img{
    	width: auto;
    	max-width: 1000px;
	    display:none;
	    position:absolute;
	    left:0;
	    top:0;
    }
}
#center{
	top: -26px;
	left: 60px;
	z-index:1000;
}