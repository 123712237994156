/* top
--------------------------------------------------------- */
.p-top-visual{
	display: none;
	@include sp{
		display: block;
		width: 100%;
		height: 500px;
		background-image: url('../img/top/background_top_sp.jpg');
        background-size: cover;
        background-position: center center;
	}
}
.p-top{

	#js-contentWrapper{
		background-image: url('../img/top/background_2020_pc.png');
		background-size: cover;
		background-position: center center;
		height: 100vh;
		@include sp{
			height: auto;
			background-image: url('../img/top/background_2020_sp.png');
		}
	}
	@include sp{
		position: relative;
		.toggle{
			display: none!important;
		}
		.l-header__logo{
			position: fixed;
			width: 120px;
			top: 48px;
		}
		.l-side{
			display: flex;
		}
	}
	.l-sidemenu li{
		color: white;
		@include sp{
			margin-bottom: 21px;
		}
	}
}