
/* store
--------------------------------------------------------- */
.p-store{
    &__title{
        font-size: 1.2rem;
        line-height: 1;
        font-weight: bold;
        color: #2a70f1;
        margin-bottom: 6px;
    }
    &__text{
        font-size: 1.2rem;
        line-height: 1.5;
        a{
            display: inline;
        }
    }
    &__list{
        >li{
            margin-bottom: 18px;
        }
    }
    &__link{
        i{
            position: relative;
            top: 2px;
            left:2px;
        }
    }
}