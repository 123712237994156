/* Header
--------------------------------------------------------- */
.l-header{
	position: fixed;
	left: 0;
	top: 0;
	z-index: 100;
	padding-top: 40px;
	padding-left: 38px;
	@include sp{
		position: static;
		padding-top: 12px;
		padding-left: 25px;
		padding-bottom: 9px;
	}
	a{
		z-index: 10;
	}
	&__logo{
		width: 140px;
		@include sp{
			width: 80px;
		}
	}
}