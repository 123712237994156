/* Layout
--------------------------------------------------------- */

$site-width:1200px;
$min-width:1200px;

$breakpoint: 700px;
@mixin sp {
    @media screen and (max-width: $breakpoint) {
        @content;
    }
}
.u-only-pc{
    display:block!important;
    @include sp{display:none!important;}
}
.u-only-sp{
    display:none!important;
    @include sp{display:block!important;}
}
body {
    min-width: $min-width;
    min-height: 100%;
    position: relative;
    @include sp{
        min-width: 100%;
    }
    .l-header{
        width: 100%;
        min-width: $min-width;
        @include sp{
            min-width: 100%;
        }
        // &__inner{
        //     width: $site-width;
        //     margin: 0 auto;
        // }
    }
    .l-content{
        width: 100%;
        min-width: $min-width;
        @include sp{
            min-width: 100%;
        }
        &__inner{
            width: $site-width;
            padding-top: 154px;
            padding-left: 296px;
            @include sp{
                width: 100%;
                padding-left: 0;
                padding-top: 0;
                padding: 20px 26px 40px;
            }
            // omotenashi
            &--full{
                width: $site-width;
                margin: 0 auto;
                padding-left: 0;
                @include sp{
                    width: 100%;
                    padding: 20px 26px 40px;
                }
            }
            // collection & archive
            &--collection{
                // width: $site-width;
                // margin: 0 auto;
                width: calc(100% - 180px);
                padding: 0;
                margin-left: 180px;
                @include sp{
                    width: 100%;
                    padding: 0;
                    margin-left: 0;
                    &:before{
                        display: none;
                    }
                }
            }
        }
    }
    .l-footer{
        width: 100%;
        min-width: $min-width;
        @include sp{
            min-width: 100%;
            height: auto;
        }
        &__inner{
            width: $site-width;
            margin: 0 auto;
            @include sp{
                width: 100%;
            }
        }
    }
}