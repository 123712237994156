/* common
--------------------------------------------------------- */
.c-language{
	margin-bottom: 38px;
	>a{
		font-size: 1.2rem;
		letter-spacing: 0.03em;
		display: inline-block;
		opacity: 0.4;
		&.is-current{
			opacity: 1;
		}
	}
	>span{}
}