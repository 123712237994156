/* スライドメニュー機能
--------------------------------------------------------- */
// z-indexの管理
$z: slidemenu,wrapper,overlay,toggle;

#js-spMenu {
    display: none;
    @include sp{
        opacity: 0;
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 280px;
        height: 100%;
        overflow-y:auto;
        -webkit-overflow-scrolling: touch;
        z-index: index($z, slidemenu);
        pointer-events: none;    
    }
}

#js-contentWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
    transition: all .5s ease;
    z-index: index($z, wrapper);
    // iOSでpointer-eventsが原因でスクロールできなくなることがあるため
    // ただし、PC版で読み込むとインスペクタが効かなくなるのでsp版だけ指定する
    // pointer-events: none;
}

#js-overlay {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: all .5s ease;
    z-index: index($z, overlay);
    pointer-events: none;
}

#js-spMenuButton{
    display: none;
    @include sp{
        // position:fixed;
        position: absolute;
        right:10px;
        top:10px;
        display:block;
        cursor:pointer;
        z-index: index($z, toggle);
        display: block;
        padding: 15px;
    }
}

#js-spMenuClose{
    cursor: pointer;
}

body.is-open{
    // bodyにoverflowでコンテンツ部分のスクロールを無効化
    overflow:hidden;
    #js-overlay {
        visibility: visible;
        cursor: pointer;
        background: rgba(0, 0, 0, .7);
        pointer-events: auto;
    }
    #js-spMenu{
        pointer-events: auto;
        opacity: 1;
    }
    #js-contentWrapper,
    #js-overlay {
        -webkit-transform: translate3d(-280px, 0, 0);
        transform: translate3d(-280px, 0, 0);
    }
}

/* スライドメニューボタン
--------------------------------------------------------- */
$size: 17;
$bar: 2;
$half: ($size - $bar) / 2;
.a-trigger{
    display: inline-block;
    transition: all .3s;
    position: relative;
    width: $size + px;
    height: $size + px;
    // ハンバーガーアイコン
    span {
        display: inline-block;
        transition: all .3s;
        position: absolute;
        left: 0;
        width: 100%;
        height: $bar + px;
        background-color: black;
        &:nth-of-type(1) {
            top: 0;
        }
        &:nth-of-type(2) {
            top: $half + px;
        }
        &:nth-of-type(3) {
            bottom: 0;
        }
    }
    // 開閉アニメーション
    &.active span{
        &:nth-of-type(1) {
            transform: translateY($half + px) rotate(-45deg);
        }
        &:nth-of-type(2) {
            opacity: 0;
        }
        &:nth-of-type(3) {
            transform: translateY(- $half + px) rotate(45deg);
        }
    }
}
/* スライドメニュー本体
--------------------------------------------------------- */
.slidemenu{
    padding-top: 16px;
    >ul > li{
        &:nth-child(7){
            margin-top: 34px;
        }
        a{
            display: block;
            padding: 10px 20px;
        }
        >ul{
            padding-left: 10px;
        }
    }
    .close{
        padding: 10px 20px;
    }
}