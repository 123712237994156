/* sara rasa
--------------------------------------------------------- */
.p-sararasa{
	margin-top: 70px;
	padding-bottom: 50px;
	@include sp{
		margin-top: 20px;
		padding: 0px 26px 40px;
	}
	&__logo{
		width: 140px;
		display: block;
		margin: 0 auto 56px;
		@include sp{
			width: 100px;
			margin-bottom: 44px;
		}
	}
	&__text{
		font-family: MS-Mincho, serif;
		font-size: 1.3rem;
		line-height: 2.23;
		letter-spacing: 0.8px;
		text-align: center;
		margin-bottom: 40px;
		@include sp{
			text-align: left;
			line-height: 1.77;
			margin-bottom: 40px;
		}
	}
	&__photo{
		display: block;
		margin: 58px auto 62px;
		@include sp{
			display: none;
		}
	}
}