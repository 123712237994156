/*
# Responsive
*/

// # Break Point
$break-point:768px;
//tab=992px
// # Media Query i6
@mixin i6 {
  @media screen and (device-width: 375px) and (device-height: 667px)  {
    @content;
  }
}
// # Media Query SP
@mixin sp {
  @media screen and (max-width: 768px) {
    @content;
  }
}
// # Media Query TAB
@mixin tb {
  @media screen and (max-width: 992px) {
    @content;
  }
}
// # Media Query PC
@mixin pc {
  @media screen and (min-width: 992px + 1) {
    @content;
  }
}
// # Display Only PC
.u-only-pc{
  display:block;
  @include sp{display:none;}
}
// # Display Only SP
.u-only-sp{
  display:none;
  @include sp{display:block;}
}

