/* information
--------------------------------------------------------- */
.p-info{
    &__title{
        font-size: 1.2rem;
        line-height: 1;
        font-weight: bold;
        color: #2a70f1;
        margin-bottom: 13px;
    }
    &__text{
        font-size: 1.2rem;
        line-height: 1.5;
        a{
            display: inline;
        }
    }
    &__list{
        >li{
            margin-bottom: 28px;
        }
    }
}